.orders-by-months-tables tr.row-bold{
  font-weight: 600;
}
.orders-by-months-tables tr.row-bold > td{
  background-color: #f1f1f1;
}
.orders-by-months-tables tr.row-bold:hover > td{
  background-color: #eeeeee; 
}
.orders-by-months-tables .ant-table-thead > tr > th{
  /* border: 1px solid #aaa;
  border-bottom: 1px solid #aaa; */
  background-color: #f1f1f1;
}